export enum Province {
  Alberta = 'ALBERTA',
  BritishColumbia = 'BRITISH_COLUMBIA',
  Manitoba = 'MANITOBA',
  NewfoundlandLabrador = 'NEWFOUNDLAND_LABRADOR',
  NewBrunswick = 'NEW_BRUNSWICK',
  NorthwestTerritories = 'NORTHWEST_TERRITORIES',
  NovaScotia = 'NOVA_SCOTIA',
  Nunavut = 'NUNAVUT',
  Ontario = 'ONTARIO',
  PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
  Quebec = 'QUEBEC',
  Saskatchewan = 'SASKATCHEWAN',
  Yukon = 'YUKON',
}

export const provinceMap: Record<Province, string> = {
  ONTARIO: 'Ontario',
  ALBERTA: 'Alberta',
  BRITISH_COLUMBIA: 'British Columbia',
  MANITOBA: 'Manitoba',
  NEW_BRUNSWICK: 'New Brunswick',
  NEWFOUNDLAND_LABRADOR: 'Newfoundland and Labrador',
  NORTHWEST_TERRITORIES: 'Northwest Territories',
  NOVA_SCOTIA: 'Nova Scotia',
  NUNAVUT: 'Nunavut',
  PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
  QUEBEC: 'Quebec',
  SASKATCHEWAN: 'Saskatchewan',
  YUKON: 'Yukon',
};
