export const minimumHomePrice = 200000;
import { ListedCity } from '@requity-homes/utils';

export const MINIMUM_HOME_PRICE_BY_CITY: Record<ListedCity, number> = {
  [ListedCity.SaultSteMarie]: 200000,
  [ListedCity.ThunderBay]: 200000,
  [ListedCity.Sudbury]: 300000,
  [ListedCity.NorthBay]: 300000,
  [ListedCity.Saskatoon]: 250000,
  [ListedCity.Regina]: 250000,
  [ListedCity.Warman]: 250000,
  [ListedCity.Martensville]: 250000,
  [ListedCity.MooseJaw]: 200000,
  [ListedCity.PrinceAlbert]: 200000,
  [ListedCity.Winnipeg]: 200000,
  [ListedCity.Calgary]: 400000,
  [ListedCity.Airdrie]: 400000,
  [ListedCity.Edmonton]: 300000,
  [ListedCity.SherwoodPark]: 300000,
  [ListedCity.Leduc]: 300000,
  [ListedCity.SpruceGrove]: 300000,
  [ListedCity.StonyPlain]: 300000,
  [ListedCity.Beaumont]: 300000,
  [ListedCity.StAlbert]: 300000,
  [ListedCity.RedDeer]: 250000,
  [ListedCity.Lethbridge]: 250000,
  [ListedCity.GrandePrairie]: 250000,
};

export const getMinHomePriceByCity = (city: string) => {
  return MINIMUM_HOME_PRICE_BY_CITY[city as ListedCity] || minimumHomePrice;
};
