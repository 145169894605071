import { useField, Field, useFormikContext } from 'formik';
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { NumericFormat } from 'react-number-format';

import { FormControl } from '../../form-control/form-control';
import { Hint } from '../../hint/hint';
import { Input } from '../../input/input';
import { Label } from '../../label/label';
import { PlaceholderText } from '../../../placeholder-text/placeholder-text';

export interface FormInputProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'id' | 'name'> {
  label?: string | ReactNode;
  name: string;
  parentClass?: string;
  disableThousandSeparator?: boolean;
  handleValueChange?: React.Dispatch<React.SetStateAction<string>>;
}

export function FormInput({
  label,
  name,
  type = 'text',
  ...props
}: FormInputProps) {
  const [field, meta] = useField({ name, type });
  const isDateField = type === 'date';
  return (
    <FormControl className={isDateField ? 'relative' : undefined}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Input
        type={type}
        {...field}
        {...props}
        value={field.value == null ? '' : field.value}
        state={meta.touched && meta.error ? 'error' : undefined}
        className={
          isDateField && !field.value
            ? `${props.className} text-transparent md:text-grey-3`
            : props.className
        }
      />
      {isDateField && !field.value && (
        <PlaceholderText
          className="absolute bottom-2 left-4 md:hidden text-grey-3"
          text="dd/mm/yyyy"
        />
      )}
      {meta.touched && meta.error && <Hint state="error">{meta.error}</Hint>}
    </FormControl>
  );
}

export function NumericFormatInput({
  label,
  name,
  className = '',
  parentClass = '',
  placeholder = '',
  disableThousandSeparator = false,
  defaultValue,
  handleValueChange,
}: FormInputProps) {
  const [field, meta] = useField({ name, type: 'text' });
  const { setFieldValue: setFormikFieldValue } = useFormikContext();
  if (defaultValue) {
    field.value = defaultValue;
  }
  return (
    <FormControl className={parentClass}>
      <Label htmlFor={name}>{label}</Label>
      <NumericFormat
        {...field}
        className={className}
        placeholder={placeholder}
        name={name}
        thousandSeparator={!disableThousandSeparator}
        customInput={Field}
        value={field.value}
        onValueChange={(val) => {
          setFormikFieldValue(field.name, val.floatValue);
          if (handleValueChange) {
            handleValueChange(val.floatValue?.toString() as string);
          }
        }}
      />
      {meta.touched && meta.error && <Hint state="error">{meta.error}</Hint>}
    </FormControl>
  );
}
