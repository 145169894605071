import { AppProps } from 'next/app';
import Head from 'next/head';
import { Amplify } from 'aws-amplify';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { ApolloWrapper } from '../graphql/apollo-client';
import awsExports from '../aws-exports';
import './styles.css';
import Script from 'next/script';
import { isTrackingEnabled, intercomScript } from '@requity-homes/utils';
import mixpanel from 'mixpanel-browser';
import { segmentSnippet, Track } from '../analytics';
import { HubSpotTracking } from '@requity-homes/component-library';

Amplify.configure(awsExports);

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_STAGE === 'prod') {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
    },
  });
}

export default function App({ Component, pageProps }: AppProps) {
  return (
    <PostHogProvider client={posthog}>
      <ApolloWrapper>
        <Head>
          <link rel="icon" href="/favicon-32.png" sizes="32x32" />
          <link rel="icon" href="/favicon-128.png" sizes="128x128" />
          <link rel="icon" href="/favicon-180.png" sizes="180x180" />
          <link rel="icon" href="/favicon-192.png" sizes="192x192" />
          <meta
            property="og:image"
            content="https://brand-assets.requityhomes.com/happy-client-handshake.png"
          />
        </Head>
        <Script id="intercom-script">
          {typeof window !== 'undefined' && window.parent === window
            ? intercomScript(process.env.NEXT_PUBLIC_INTERCOM_APP_ID)
            : null}
        </Script>
        {isTrackingEnabled() && (
          <Script
            id="segment-script"
            dangerouslySetInnerHTML={{ __html: segmentSnippet() }}
          />
        )}
        {isTrackingEnabled() &&
          mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)}
        <Track></Track>
        <Component {...pageProps} />
        <HubSpotTracking isTrackingEnabled={true} />
      </ApolloWrapper>
    </PostHogProvider>
  );
}
