import snippet from '@segment/snippet';
import { Auth, Hub } from 'aws-amplify';
import { identifyUser, trackEvent } from '@requity-homes/component-library';
import { isLocal } from '@requity-homes/utils';

const stage = process.env.NEXT_PUBLIC_STAGE;

interface CurrentUserInfo {
  username: string;
  attributes: {
    sub: string;
    email_verified: boolean;
    phone_number_verified: boolean;
    phone_number: string;
    given_name: string;
    family_name: string;
    email: string;
  };
}

function updateIntercomUserInfo(user: CurrentUserInfo) {
  if (window.Intercom) {
    window.Intercom('update', {
      name: user?.attributes?.given_name,
      email: user?.attributes?.email,
    });
  }
}

Hub.listen('auth', async (data) => {
  // Second condition is added to prevent false error on the server-side
  if (data.payload.event === 'configured' && typeof window !== 'undefined') {
    const user: CurrentUserInfo | null = await Auth.currentUserInfo();

    if (user) {
      await identifyUser(
        user.attributes?.sub,
        user.attributes?.email,
        user.attributes?.given_name,
        user.attributes?.family_name,
      );
    }
  }

  if (data.payload.event === 'signIn' || data.payload.event === 'signUp') {
    const user: CurrentUserInfo | null = await Auth.currentUserInfo();
    if (user) {
      await identifyUser(
        user.attributes?.sub,
        user.attributes?.email,
        user.attributes?.given_name,
        user.attributes?.family_name,
      );
      trackEvent('login', {
        user_id: user.attributes?.sub,
      });

      if (!isLocal(stage)) {
        updateIntercomUserInfo(user);
      }
    }
  }
});

export function segmentSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}
