import { useField } from 'formik';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

import { FormControl, Hint, Textarea, Label } from '../..';

export interface FormTextareaProps
  extends Omit<ComponentPropsWithoutRef<'textarea'>, 'id' | 'name'> {
  label?: string | ReactNode;
  name: string;
}

export function FormTextarea({ label, name, ...props }: FormTextareaProps) {
  const [field, meta] = useField({ name });
  return (
    <FormControl>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Textarea
        {...field}
        {...props}
        value={field.value == null ? '' : field.value}
        state={meta.touched && meta.error ? 'error' : undefined}
      />
      {meta.touched && meta.error && <Hint state="error">{meta.error}</Hint>}
    </FormControl>
  );
}
