import { ListedCity } from '@requity-homes/utils';

export const minimumSavings = 5000;

export const MINIMUM_SAVINGS_BY_CITY: Record<ListedCity, number> = {
  [ListedCity.SaultSteMarie]: 5000,
  [ListedCity.ThunderBay]: 5000,
  [ListedCity.Sudbury]: 6000,
  [ListedCity.NorthBay]: 6000,
  [ListedCity.Saskatoon]: 5000,
  [ListedCity.Regina]: 5000,
  [ListedCity.Warman]: 5000,
  [ListedCity.Martensville]: 5000,
  [ListedCity.MooseJaw]: 5000,
  [ListedCity.PrinceAlbert]: 5000,
  [ListedCity.Winnipeg]: 5000,
  [ListedCity.Calgary]: 10000,
  [ListedCity.Airdrie]: 10000,
  [ListedCity.Edmonton]: 6000,
  [ListedCity.Beaumont]: 6000,
  [ListedCity.SherwoodPark]: 6000,
  [ListedCity.Leduc]: 6000,
  [ListedCity.SpruceGrove]: 6000,
  [ListedCity.StonyPlain]: 6000,
  [ListedCity.StAlbert]: 6000,
  [ListedCity.RedDeer]: 5000,
  [ListedCity.Lethbridge]: 5000,
  [ListedCity.GrandePrairie]: 5000,
};

export const getMinSavingsByCity = (city: string) => {
  return MINIMUM_SAVINGS_BY_CITY[city as ListedCity] || minimumSavings;
};
