import { ComponentPropsWithRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { StateOptions } from '../types';

export interface TextareaProps extends ComponentPropsWithRef<'textarea'> {
  state?: StateOptions;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ state = 'default', className = '', ...props }, ref) => {
    const styles = `${baseStyles} ${styleMap[state]}`;
    return (
      <textarea
        ref={ref}
        {...props}
        className={twMerge(`${styles} ${className || ''}`)}
      ></textarea>
    );
  },
);

type StylesConfig = Record<StateOptions, string>;

const baseStyles =
  'py-2 px-4 text-base border text-grey-1 placeholder:text-grey-4 disabled:bg-grey-5 placeholder:disabled:text-grey-3 disabled:cursor-not-allowed';

const styleMap: StylesConfig = {
  default: 'border-grey-4',
  success: 'border-success-dark',
  error: 'border-error-dark',
};
