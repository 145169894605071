export enum ListedCity {
  Edmonton = 'Edmonton',
  SpruceGrove = 'Spruce Grove',
  StAlbert = 'St. Albert',
  StonyPlain = 'Stony Plain',
  SherwoodPark = 'Sherwood Park',
  RedDeer = 'Red Deer',
  Lethbridge = 'Lethbridge',
  GrandePrairie = 'Grande Prairie',
  Airdrie = 'Airdrie',
  Beaumont = 'Beaumont',
  Leduc = 'Leduc',
  NorthBay = 'North Bay',
  SaultSteMarie = 'Sault Ste. Marie',
  Sudbury = 'Sudbury',
  ThunderBay = 'Thunder Bay',
  Saskatoon = 'Saskatoon',
  Martensville = 'Martensville',
  Warman = 'Warman',
  Regina = 'Regina',
  PrinceAlbert = 'Prince Albert',
  MooseJaw = 'Moose Jaw',
  Winnipeg = 'Winnipeg',
  Calgary = 'Calgary',
}
