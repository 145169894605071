import { ListedCity } from './listed-city';

export const ListedCityBoundaryMap: Partial<Record<ListedCity, number[]>> = {
  [ListedCity.Edmonton]: [-113.7381896, 53.3376694, -113.2716155, 53.7304996],
  [ListedCity.SpruceGrove]: [-114.0060589, 53.342514, -113.6433988, 53.6634754],
  [ListedCity.StAlbert]: [-113.7149518, 53.5921301, -113.56579, 53.6928619],
  [ListedCity.StonyPlain]: [-114.068393, 53.4974021, -113.9591231, 53.5697786],
  [ListedCity.SherwoodPark]: [
    -113.3569044, 53.3543316, -112.7410542, 53.6285882,
  ],
  [ListedCity.RedDeer]: [-113.8855488, 52.215708, -113.7187409, 52.3298704],
  [ListedCity.Lethbridge]: [-112.9356352, 49.6109239, -112.7475627, 49.7593574],
  [ListedCity.GrandePrairie]: [
    -118.9744954, 55.1124435, -118.6784616, 55.2425351,
  ],
  [ListedCity.Airdrie]: [
    -114.14290905882781, 51.21344506126712, -113.88548635286764,
    51.36971648202706,
  ],
  [ListedCity.Beaumont]: [-113.4641773, 53.3218336, -113.390788, 53.3822873],
  [ListedCity.Leduc]: [-113.6104173, 53.2357384, -113.4660348, 53.308831],
  [ListedCity.NorthBay]: [-79.8306111, 46.2157015, -79.2559589, 46.4492801],
  [ListedCity.SaultSteMarie]: [
    -84.7608958, 46.4528777, -84.2283256, 46.6994672,
  ],
  [ListedCity.Sudbury]: [-81.3074125, 46.1031557, -80.5670573, 46.5372848],
  [ListedCity.ThunderBay]: [-89.4293129, 48.2892219, -89.044562, 48.5153969],
  [ListedCity.Saskatoon]: [-106.8243914, 52.0695261, -106.5037051, 52.2312215],
  [ListedCity.Martensville]: [
    -106.7125138, 52.2536415, -106.6173459, 52.3200624,
  ],
  [ListedCity.Warman]: [-106.6887418, 52.2370763, -106.4482174, 52.4048168],
  [ListedCity.Regina]: [-104.7212891, 50.3821071, -104.4802027, 50.5216514],
  [ListedCity.PrinceAlbert]: [-105.8786427, 53.1161582, -105.6258228, 53.28894],
  [ListedCity.MooseJaw]: [-105.6263234, 50.3459939, -105.4773329, 50.4257662],
};
