import { Fragment } from 'react';
import { Switch as ReactSwitch } from '@headlessui/react';

export interface SwitchProps {
  enableToggle: boolean;
  setEnableToggle: (checked: boolean) => void;
}

export function Switch({ enableToggle, setEnableToggle }: SwitchProps) {
  return (
    <ReactSwitch
      checked={enableToggle}
      onChange={setEnableToggle}
      as={Fragment}
    >
      {({ checked }) => (
        /* Use the `checked` state to conditionally style the button. */
        <button
          className={`${
            checked ? 'bg-coral-dark' : 'bg-grey-4'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              checked ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </button>
      )}
    </ReactSwitch>
  );
}
