export enum Province {
  Alberta = 'ALBERTA',
  BritishColumbia = 'BRITISH_COLUMBIA',
  Manitoba = 'MANITOBA',
  NewfoundlandLabrador = 'NEWFOUNDLAND_LABRADOR',
  NewBrunswick = 'NEW_BRUNSWICK',
  NorthwestTerritories = 'NORTHWEST_TERRITORIES',
  NovaScotia = 'NOVA_SCOTIA',
  Nunavut = 'NUNAVUT',
  Ontario = 'ONTARIO',
  PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
  Quebec = 'QUEBEC',
  Saskatchewan = 'SASKATCHEWAN',
  Yukon = 'YUKON',
}

export const provinceToAbbreviationMap: Record<Province, string> = {
  [Province.Alberta]: 'AB',
  [Province.BritishColumbia]: 'BC',
  [Province.Manitoba]: 'MB',
  [Province.NewfoundlandLabrador]: 'NL',
  [Province.NewBrunswick]: 'NB',
  [Province.NorthwestTerritories]: 'NT',
  [Province.NovaScotia]: 'NS',
  [Province.Nunavut]: 'NU',
  [Province.Ontario]: 'ON',
  [Province.PrinceEdwardIsland]: 'PE',
  [Province.Quebec]: 'QC',
  [Province.Saskatchewan]: 'SK',
  [Province.Yukon]: 'YT',
};

export const provinceAbbreviationMap: Record<string, Province> = {
  AB: Province.Alberta,
  BC: Province.BritishColumbia,
  MB: Province.Manitoba,
  NL: Province.NewfoundlandLabrador,
  NB: Province.NewBrunswick,
  NT: Province.NorthwestTerritories,
  NS: Province.NovaScotia,
  NU: Province.Nunavut,
  ON: Province.Ontario,
  PE: Province.PrinceEdwardIsland,
  QC: Province.Quebec,
  SK: Province.Saskatchewan,
  YT: Province.Yukon,
};
