import {
  Button,
  AsyncHandler,
  FormInput,
  Link,
  Logo,
  Text,
  sendLoginLink,
  Heading,
} from '..';
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';

export interface SignInFormProps {
  url: string;
}

const parseURLParams = (
  url: string,
): {
  isExpired?: string;
  isInvalid?: string;
} => {
  const qs = url.split('?')[1];
  const qsParams = qs.split('&');
  const qaIsExpired = qsParams.find((x) => x.startsWith('isExpired='));
  const qaIsInvalid = qsParams.find((x) => x.startsWith('isInvalid='));

  if (qaIsExpired) {
    const isExpired = decodeURIComponent(qaIsExpired.substring(10));
    return { isExpired };
  }

  if (qaIsInvalid) {
    const isInvalid = decodeURIComponent(qaIsInvalid.substring(10));
    return { isInvalid };
  }

  return { isExpired: 'false', isInvalid: 'false' };
};

export function SignInForm({ url }: SignInFormProps) {
  const initialValues = { email: '' };
  const { asPath } = useRouter();
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';
  const currentUrl = `${origin}${asPath}`;
  const [message, setMessage] = useState<string>('Login to Requity Homes');

  const [isAuthenticated, setIsAuthenticated] = useState<undefined | boolean>(
    undefined,
  );
  const [error, setError] = useState<undefined | string>(undefined);
  const router = useRouter();

  useEffect(() => {
    Auth.currentUserInfo()
      .then((userInfo) => {
        if (userInfo) {
          setIsAuthenticated(true);
          router.push('/');
        } else {
          setIsAuthenticated(false);
          const { isExpired, isInvalid } = parseURLParams(currentUrl);
          if (isExpired === 'true') {
            setMessage('Log in link is expired');
          }
          if (isInvalid === 'true') {
            setMessage('Log in link is invalid');
          }
        }
      })
      .catch(() => {
        setError('Error checking authentication status');
      });
  }, []);

  const handleSubmit = (values: { email: string }) => {
    // Handle form submission logic here
    return sendLoginLink(url, values.email);
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Please provide a valid email.')
      .required('Email is required'),
  });

  return (
    <div className="flex flex-col min-h-screen">
      <AsyncHandler
        loading={isAuthenticated === undefined}
        error={undefined}
        errorMessage={error}
        errorStyles="flex-grow"
      >
        <header className="w-full px-6 pt-6 text-center md:text-left">
          <Link href="https://requityhomes.com/" target="_blank">
            <Logo color="coral" className="md:max-h-12 max-h-10 w-40" />
          </Link>
        </header>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched, dirty, submitForm, isSubmitting }) => (
            <main className={'flex flex-col flex-grow max-w-4xl self-center'}>
              <Heading
                level="h1"
                element="h1"
                fontFace="sans"
                className="px-8 mt-5 md:text-5xl text-3xl font-bold text-center"
              >
                {message}
              </Heading>
              <Form className="flex-grow w-9/12 mx-auto">
                <Text className="px-8 mt-8 mb-14 text-center">
                  Enter your email and we&apos;ll send you a login link
                </Text>
                <FormInput type="email" name="email" placeholder="Email" />
              </Form>

              <div className="flex flex-col items-center self-center w-9/12 mt-8">
                <Button
                  type="submit"
                  onClick={() => submitForm()}
                  className="sm:self-start sm:px-0 mb-4 shadow-md-dark border-none w-full disabled:bg-grey-5 disabled:shadow-inset disabled:text-grey-3 bg-coral-med"
                  disabled={!dirty || !!errors.email || isSubmitting}
                  color={'coral'}
                >
                  Send log in email
                </Button>
                <Text className="px-8 pb-8 text-center font-bold text-lg">
                  Don&apos;t have an account?{' '}
                  <Link
                    href="/auth/sign-up"
                    className="text-coral-med no-underline"
                  >
                    Sign Up
                  </Link>
                </Text>
              </div>
            </main>
          )}
        </Formik>
      </AsyncHandler>
      ,
    </div>
  );
}
