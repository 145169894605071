import { ListedCity } from '@requity-homes/utils';

export const minimumIncome = 70000;

export const MINIMUM_INCOME_BY_CITY: Record<ListedCity, number> = {
  [ListedCity.SaultSteMarie]: 70000,
  [ListedCity.ThunderBay]: 70000,
  [ListedCity.Sudbury]: 80000,
  [ListedCity.NorthBay]: 80000,
  [ListedCity.Saskatoon]: 70000,
  [ListedCity.Regina]: 70000,
  [ListedCity.Warman]: 70000,
  [ListedCity.Martensville]: 70000,
  [ListedCity.MooseJaw]: 60000,
  [ListedCity.PrinceAlbert]: 60000,
  [ListedCity.Winnipeg]: 70000,
  [ListedCity.Calgary]: 120000,
  [ListedCity.Airdrie]: 120000,
  [ListedCity.Edmonton]: 90000,
  [ListedCity.Beaumont]: 90000,
  [ListedCity.SherwoodPark]: 90000,
  [ListedCity.Leduc]: 90000,
  [ListedCity.SpruceGrove]: 90000,
  [ListedCity.StonyPlain]: 90000,
  [ListedCity.StAlbert]: 90000,
  [ListedCity.RedDeer]: 70000,
  [ListedCity.Lethbridge]: 70000,
  [ListedCity.GrandePrairie]: 70000,
};

export const getMinIncomeByCity = (city: string) => {
  return MINIMUM_INCOME_BY_CITY[city as ListedCity] || minimumIncome;
};
