import { getEnvironmentBaseUrl, Environment } from '.';
import { Base64 } from 'js-base64';
import { MARKETING_SITE, UtmSource } from '../const';

interface ReferralInfo {
  id: string;
  givenName: string;
  familyName: string;
  partnerType: string;
}

export function getPartnerReferralLink(referralInfo: ReferralInfo): string {
  const partnerType = referralInfo.partnerType?.replace(' ', '_');
  const utmSource =
    partnerType?.toLowerCase() === UtmSource.CUSTOMER
      ? UtmSource.CUSTOMER
      : partnerType?.toLowerCase();

  return `${MARKETING_SITE}?referral=${Base64.encode(
    referralInfo.id,
  )}&utm_source=${utmSource}&utm_medium=referral&utm_campaign=direct_invite&utm_content=${partnerType?.toLowerCase()}&utm_term=${referralInfo.givenName?.toLowerCase()}_${referralInfo.familyName?.toLowerCase()}`;
}

export function getOldPartnerReferralLink(
  referralInfo: ReferralInfo,
  stage: string = Environment.Production,
): string {
  const partnerType = referralInfo.partnerType?.replace(' ', '_');
  return `${getEnvironmentBaseUrl(stage)}/auth/sign-up?referral=${Base64.encode(
    referralInfo.id,
  )}&utm_source=partner&utm_medium=referral&utm_campaign=direct_invite&utm_content=${partnerType?.toLowerCase()}&utm_term=${referralInfo.givenName?.toLowerCase()}_${referralInfo.familyName?.toLowerCase()}`;
}
